.awssld--cubeAnimation{
    --cube-animation-duration: 1675ms;
  }


.pr-primary-carousel {
    height: 40em;
}
/* .pr-primary-carousel  img{
    height: 40em;
} */
.pr-primary-carousel-img {
    z-index: 1;
}

.awssld {
--loader-bar-color: transparent;
}

.pr-primary-carousel .awssld__content > img{
    opacity: 60%;
}

.pr-primary-carousel__text {
    font-weight: 900;
    font-size: 60px;
    color: white;
    justify-content: center;
    position: relative;
    bottom: 60px;
    text-align: center;
}

.pr-primary-carousel__text-highlight {
    color: #AECCEA;
}

.pr-primary-carousel-inline {
    display: inline-flex;
    justify-content: center;
}

@media (max-width: 1410px){
    .pr-primary-carousel__text {
        font-size: 50px;
    }
}

@media (max-width: 1210px){
    .pr-primary-carousel__text {
        font-size: 40px;
        top: 1rem;
    }
}

@media (max-width: 1100px) {
.pr-primary-carousel__text {
    top: 2rem;
}
}

@media (max-width: 990px){
    .pr-primary-carousel__text {
        font-size: 30px;
        top: 3rem;
    }

    .pr-primary-carousel {
        height: unset;
    }
}

@media (max-width: 825px){
    .pr-primary-carousel__text {
        font-size: 20px;
        top: 5rem;
    }
}

@media (max-width: 435px){
    .pr-primary-carousel__text {
        font-size: 15px;
    }
}

@media (max-width: 342px){
    .pr-primary-carousel__text {
        top: 3rem;
    }
}