.pr-sc-section{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pr-sccarousel-carousel .awssld__content > img{
    opacity: 70%;
}

.pr-sccarousel-carousel{
    margin-left: 2.5rem;
    width: 60rem;
}

.pr-sccarousel-grouptext{
    margin: 0 2.5rem;
}

.pr-sc-img {
    z-index: 1;
    position: relative;
}

.pr-sc-carousel {
    box-shadow: 10px 10px 15px #AECCEA;
}

.pr-sc-text {
    position: absolute;
    color: white;
    font-size: 50px;
    font-weight: 900;
    left: -450px;
    bottom: -290px;
}

.pr-sccarousel-text{
    display: flex;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 1rem;
}

@media (max-width: 1270px){
    .pr-sccarousel-text{
        font-size: 25px;
    }
}

@media (max-width: 1240px) {
    .pr-sc-text {
        left: -420px;
        bottom: -270px;
    }
}

@media (max-width: 1110px) {
    .pr-sc-text {
        left: -390px;
        bottom: -230px;
    }
}

@media (max-width: 1030px){
    .pr-sccarousel-text{
        font-size: 30px;
    }
}

@media (max-width: 1047px){
    .pr-sc-text {
        left: -350px;
    }
}

@media (max-width: 1000px){
    .pr-sc-text {
        left: -470px;
        bottom: -300px;
    }
}

@media (max-width: 950px){
    .pr-sc-text {
        left: -430px;
        bottom: -250px;
    }
}

@media (max-width: 880px){
    .pr-sc-text {
        left: -380px;
        bottom: -200px;
    }
}

@media (max-width: 770px){
    .pr-sc-text {
        left: -330px;
    }
}

@media (max-width: 670px){
    .pr-sc-text {
        left: -290px;
    }
}

@media (max-width: 630px){
    .pr-sc-text {
        left: -200px;
        bottom: -150px;
    }
}

@media (max-width: 412px){
    .pr-sc-text {
        left: -150px;
    }
}

@media (max-width: 1600px){
    .pr-sccarousel-text{
        font-size: 35px;
    }
}
@media (max-width: 1500px){
    .pr-sccarousel-text{
        font-size: 25px;
    }
}
@media (max-width: 1160px){
    .pr-sccarousel-text{
        font-size: 20px;
    }
}


@media (max-width: 1000px){
    .pr-sc-section{
        display: block;
    }
    .pr-sccarousel-carousel{
        width: 100%;
        margin: 0px;
    }
    .pr-sccarousel-grouptext{
        margin-top: 6rem;
        display: flex;
        column-gap: 1rem;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }
}
@media(max-width: 365px){
    .pr-about-us-first-image {
        width: 95%;
        left: -18px;
    }
    .pr-about-us-second-image {
        right: -20px;
        bottom: -40px;
    }
}
@media(max-width: 345px) {
    .pr-sc-text {
        top: 60px;
    }
}
@media(max-width: 310px) {
    .pr-sc-text {
        left: -130px;
        top: 40px;
    }
}


