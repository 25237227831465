.pr-navbar-backgroundcolor {
    background-color: #3C74AB;
}

.pr-navbar-textcolor{
    color: #fff;
}

.pr-mr-1 {
    margin-right: 1rem;
}

.pr-image-display {
    display: none;
}

.pr-navbar-center-icon {
    align-content: center;
    display: grid;
}

.pr-navbar-gap {
    gap: 0.9rem;
}

.pr-navbar-icon__space {
    margin-right: 0.5rem;
}

.pr-navbar__inlinegrid {
    display: inline-grid;
}

@media (max-width: 1024px) {
    .pr-image-display {
        display: block;
    }
}

@media (min-width: 1024px) and (max-width: 1147px){
    .lg\:flex-1{
        flex: unset;
    }
}

@media (min-width: 640px){
    .pr-navbar-media__socialicons{
        display: none;
    }
}


