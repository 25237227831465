.pr-clientlogos-content{
    margin-top: 8rem;
    background-color: gainsboro;
    padding: 2rem 0rem;
}

.pr-clientlogos-img {
    filter: none;
    width: 60%;
    aspect-ratio: 3/2;
    object-fit: contain;
}