.pr-galery-section {
  display: flex;
  justify-content: center;
}

.short-img {
  height: 50%;
  padding: 5px;
  border-radius: 20px;
}

.large-img {
  height: 100%;
  padding: 5px;
  border-radius: 20px;
}

.text-theme-color {
  color: #3c74ab;
}
.margin-20px-bottom {
  margin-bottom: 20px;
}
.margin-40px-top {
  margin-top: 40px;
}
.text-center {
  text-align: center !important;
}

.margin-15px-bottom {
  margin-bottom: 15px;
}

.font-weight-400 {
  font-weight: 400;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.margin-15px-bottom {
  margin-bottom: 15px;
}
.font-weight-400 {
  font-weight: 400;
}
.letter-spacing-3 {
  letter-spacing: 3px;
}
.font-size13 {
  font-size: 22px;
  line-height: normal;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.line-height-45 {
  line-height: 45px;
}
.font-weight-500 {
  font-weight: 500;
}
.font-size38 {
  font-size: 38px;
  line-height: normal;
}

.pr-gi-display {
  display: flex;
}

@media (max-width: 1200px) {
  .pr-gi-hidden {
    display: none;
  }
}

@media (max-width: 900px) {
  .pr-gi-display {
    display: grid;
  }
  .pr-galery-section {
    margin-top: 8rem;
  }
  .pr-gi-display {
    margin-top: 5rem;
  }
  .pr-gi-text {
    position: relative;
    top: 5rem;
}
}

@media (min-width: 841px) and (max-width: 900px){
    .pr-gi-text {
        top: 0rem;
    }
    .pr-galery-section {
        top: -5rem;
        position: relative;
    }
}

@media (max-width: 840px) {
    .pr-gi-text {
        top: 8rem;
    }
    .pr-galery-section {
        margin-top: 5rem;
    }
    .pr-gi-display {
        margin-top: 8rem;
    }
}

@media (max-width: 666px) {
    .pr-gi-text {
        top: 12rem;
    }
    .pr-gi-display {
        margin-top: 12rem;
      }
}

@media (max-width: 594px) {
    .pr-gi-text {
      top: 15rem;
    }
    .pr-gi-display {
        margin-top: 15rem;
    }
}

@media (max-width: 500px) {
    .pr-gi-text {
      top: 18rem;
    }
    .pr-gi-display {
        margin-top: 18rem;
    }
}

@media (max-width: 456px) {
    .pr-gi-text {
        top: 22rem;
    }
    .pr-gi-display {
        margin-top: 22rem;
    }
}

@media (max-width: 412px) {
    .pr-gi-text {
        top: 25rem;
    }
    .pr-gi-display {
        margin-top: 25rem;
    }
}

@media (max-width: 373px) {
    .pr-gi-text {
        top: 30rem;
    }
    .pr-gi-display {
        margin-top: 30rem;
    }
}

@media (max-width: 344px) {
    .pr-gi-text {
        top: 38rem;
    }
    .pr-gi-display {
        margin-top: 38rem;
    }
}