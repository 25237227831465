@font-face {
  font-family: 'Catamaran';
  src:url('../src/fonts/Catamaran.ttf.woff') format('woff'),
      url('../src/fonts/Catamaran.ttf.svg#Catamaran') format('svg'),
      url('../src/fonts/Catamaran.ttf.eot'),
      url('../src/fonts/Catamaran.ttf.eot?#iefix') format('embedded-opentype'); 
  font-weight: normal;
  font-style: normal;
}

.pr-app-whatsapp{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  width: 4em;
}

.w3-center, .w3-animate-top{
  animation-iteration-count: 2;
}

.App {
  font-family: "Catamaran";
  color: #111211;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App section {
  margin: 0 auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .pr-app-whatsapp{
    width: 3em;
  }
}

@media (max-width: 500px) {
  .pr-app-whatsapp{
    width: 2em;
  }
}

