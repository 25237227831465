.pr-dinamicnum-section {
    display: flex;
    justify-content: center;
    column-gap: 5rem;
    padding: 10rem 0;
}

.pr-dinamicnum-media-text {
    font-size: 80px;
}

.pr-dinamicnum-text {
    display: flex;
    justify-content: center;
    font-weight: 900;
}

@media (max-width: 1000px){
    .pr-dinamicnum-section{
        display: grid;
    }

    .pr-dinamicnum-media-text {
        justify-content: center;
        display: flex;
    }
}
@media (max-width: 345px){
    .pr-dinamicnum-media-text {
        font-size: 60px;
    }
}