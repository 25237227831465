.pr-aboutus-container {
  margin-bottom: 10em;
  margin-top: 10em;
  display: flex;
  justify-content: center;
}
.pr-aboutus-content {
  margin-left: 10em;
  margin-top: 2em;
  max-width: 30%;
  z-index: 1;
}
.pr-aboutus-pretitle {
  color: #3c74ab;
  font-size: 22px;
  text-transform: uppercase!important;
}
.pr-aboutus-title {
  margin-bottom: 30px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 40px;
  color: #232323;
  padding: 0;
  line-height: 1.2em;
}
.pr-aboutus-content p {
  font-size: 16px;
}
.pr-aboutus-content ul {
  margin-left: 1em;
  list-style-position: outside;
}
.pr-aboutus-content span {
  display: flex;
}
.pr-aboutus-content li {
  font-size: 16px;
  list-style: none;
  margin-bottom: 5px;
}
.pr-aboutus-list-icon {
  color: #aeccea;
  margin-top: 3px;
  margin-right: 5px;
}
.pr-aboutus-images {
  width: 300px;
  overflow: visible;
  display: flex;
  position: relative;
  margin-bottom: 50px;
  border-top: 10px solid #f4f3f3;
  border-right: 10px solid #f4f3f3;
  border-left: 10px solid #f4f3f3;
}
.pr-about-us-first-image {
  width: 100%;
  position: absolute;
  top: 20px;
  left: -25px;
  height: 400px;
}
.pr-about-us-second-image {
  width: 50%;
  z-index: 2;
  right: -60px;
  bottom: 0px;
  position: absolute;
}

@media (max-width: 1450px) {
  .pr-aboutus-images {
    margin-bottom: 100px;
    top: 30px;
  }
}

@media (max-width: 1218px) {
  .pr-aboutus-images {
    margin-bottom: 125px;
    top: 60px;
  }
}

@media (max-width: 1135px) {
  .pr-aboutus-images {
    margin-bottom: 145px;
    top: 90px;
  }
}

@media (max-width: 987px) {
  .pr-aboutus-images {
    margin-bottom: 195px;
  }
}

@media (max-width: 956px) {
  .pr-aboutus-images {
    margin-bottom: 198px;
  }
}

@media (max-width: 946px) {
  .pr-aboutus-images {
    margin-bottom: 218px;
    top: 110px;
  }
}

@media (max-width: 887px) {
  .pr-aboutus-images {
    margin-bottom: 242px;
    top: 120px;
  }
}

@media (max-width: 841px) {
  .pr-aboutus-container {
    flex-direction: column;
  }
  .pr-aboutus-images {
    padding-bottom: 23.5rem;
    left: 50%;
    top: -1rem;
    transform: translate(-50%, 0%);
  }
  .pr-aboutus-content {
    position: absolute;
    transform: translate(0%, 50%);
    margin-left: 5em;
    margin-top: 20em;
    max-width: none;
    margin-right: 5em;
  }
}
@media (max-width: 400px) {
  .pr-about-us-second-image {
    width: 35%;
    right: -40px;
  }
}
@media (max-width: 365px) {
  .pr-about-us-second-image {
    width: 30%;
    right: -30px;
  }
}
@media (max-width: 345px) {
  .pr-aboutus-images {
    display: none;
  }
  .pr-aboutus-content {
    transform: translate(0%, 25%);
  }
}
@media (max-width: 310px) {
  .pr-aboutus-content {
    margin-left: 3rem;
  }
}
